<template>
    <div class="main_v8">
        <div>
					<div class="fleet_header">
            <h3 class="detailsTitle">Fleet</h3>
            <p>(Refreshing list every 5 seconds)</p>
					</div>
					<div class="fleet_body">
            <table class="fleetTable">
                <tr>
                    <th>Vehicle</th>
                    <th>Status</th>
                    <th>Version</th>
                    <th>Location</th>
                </tr>
                <tr v-for="row in fleetlist" :key="row.index" @click="highlightRow(row.rowdata[0],row)">
                    <td v-if="row.index!='header'">{{ row.rowdata[1] }}</td>
                    <td v-if="row.index!='header'" v-html="Status(row.rowdata[5])"></td>
                    <td v-if="row.index!='header'">{{ row.rowdata[2] }}</td>
                    <td v-if="row.index!='header'">{{ row.rowdata[3] }}</td>
                </tr>
            </table>
					</div>
        </div>
        <div class="profiletilelist">
					
    			<div class="context_shadow" v-if="profile" @click.stop="profile = false"></div>
            <div class="profiletile" v-if="profile">
                <div class="profiletile_top">
                    <h3>Details for {{ currentRow.rowdata[1] }} at {{ currentRow.rowdata[3] }}</h3>
                    <span @click="profile = !profile"></span>
                </div>
                <p v-if="loadingData">Loading...</p>
								<div class="detail_table">
                	<table v-if="!loadingData && currentRow.rowdata" class="detailTable">
                    <tr v-if="currentRow.rowdata[5]==0">
                        <td>Last Recorded TimeStamp<br>(Data details below last recorded)</td>
                        <td>{{ fleetdetails.lasttimestamp }}</td>   
                    </tr>
                    <tr v-if="currentRow.rowdata[5]==1">
                        <td colspan="2" class="clive">Currently LIVE</td>    
                    </tr>
                    <tr>
                        <td>UPS Status</td>
                        <td v-if="fleetdetails.cellstrength">{{ upsStatus }}</td>
                        <td v-if="!fleetdetails.cellstrength">--- %</td>
                    </tr>
                    <tr>
                        <td>Battery Charge</td>
                        <td v-if="fleetdetails.batterypercent2">{{ fleetdetails.batterypercent2 }}%</td>
                        <td v-if="!fleetdetails.batterypercent2">--- %</td> 
                    </tr>
                    <tr>
                        <td>Battery Voltage<br>(Should be greater than 13 Volts)</td>
                        <td v-if="fleetdetails.batteryvoltage">{{ fleetdetails.batteryvoltage }} Volts</td>
                        <td v-if="!fleetdetails.batteryvoltage">--- Volts</td>
                    </tr>
                    <tr>
                        <td>CPU Usage</td><td>{{ fleetdetails.cpuusage }}%</td>
                    </tr>
                    <tr>
                        <td>Harddrive Usage Percent</td><td>{{ fleetdetails.hddusage }}%</td>
                    </tr>
                    <tr>
                        <td>Firmware core average scan time<br>(Less than 10ms is healthy)</td><td>{{ fleetdetails.coreave }} ms</td>
                    </tr>
                    <tr>
                        <td>Firmware Build</td><td>{{ fleetdetails.iriscorebuild }}</td>
                    </tr>
                    <tr>
                        <td>Drive Life Usage Percent</td>
                        <td v-if="fleetdetails.drivelife">{{ fleetdetails.drivelife*10 }}%</td>
                        <td v-if="!fleetdetails.drivelife">--- %</td>
                    </tr>
                    <tr>
                        <td>Service Port</td>
                        <td v-if="fleetdetails.phonehomeport">{{ fleetdetails.phonehomeport }}</td>
                        <td v-if="!fleetdetails.phonehomeport">---</td>
                    </tr>
                	</table>
								</div>
            </div>
        </div>
    </div>
        
    <fleetupdater 
      :src="fleetlisturl"
      :scanrate="scanrate"
      :controls=false
      @on-data="UpdateFleet">
    </fleetupdater>
    <fleetdetailupdater 
      :src="fleetdetailurl"
      :scanrate="scanrate"
      :controls=false
      @on-data="UpdateFleetDetail">
    </fleetdetailupdater>
  </template>
  
  <script setup>
    import { ref, computed } from 'vue';
    import '../../src/assets/boeing/default.scss';
    import fleetupdater from '../components/livedata/data2.vue';
    import fleetdetailupdater from '../components/livedata/data2.vue';

    const upsStates = 
      {
        "OFF": "Off",
        "OB": "On battery",
        "OL": "Online",
        "LB": "Low battery",
        "CHRG": "Charging",
        "OL CHRG": "Online, Charging",
        "OL LB": "Online, low battery",
        "OL CHRG LB": "Online, Charging, Low Battery",
        "RB": "Replace battery",
        "OVER": "Overload"
      };

    const upsStatus = computed(() => {
        return upsStates[fleetdetails.value.batterystatus];
    });

    let fleetlist = ref([]);
    let fleetdetails = ref({});
    let currentRow = ref([]);
    let loadingData = ref(false);
    let profile = ref(false);
    let fleetdetailurl = "";

    const scanrate = ref(5000);
    const fleetlisturl = "https://boeing.dreamlifter.wave7.co/screens/apps/fleet/scripts/fleetjson.php";
    
    function UpdateFleet(newdata) {
      fleetlist.value = newdata; 
    }

    function UpdateFleetDetail(newdata) {
      loadingData.value = false;
      if(newdata!="-2") {
        fleetdetails.value = newdata; 
        console.log("fleetdetails",newdata);
      } else {
        fleetdetails.value = {};
      } 
    }

    function highlightRow(id,row) {
      profile.value = true;
      loadingData.value = true;
      currentRow.value = row;
      fleetdetailurl = "https://boeing.dreamlifter.wave7.co/screens/apps/fleet/scripts/gettagsv8.php?dropletid="+id+"&group=FleetManagement";
    }

    function Status(val) {
      if(val==0) {
        return "<label style='color:#888;'>OFF</label>";
      } else if(val==1) {
        return "<label style='color:white;background-color:green;padding:4px;border-radius:8px;'>ON</label>";
      } else {
        return "Status";
      }
    }

  </script>
  
  <style lang="scss" scoped>
    .main_v8 {
      background-color: #fff;
      padding: 0px;
    }
    .clive {
      text-align:center;
      background-color: green;
      color:white;
      font-weight:bold;
    }
    .fleet_header {
      background-color: #f2f2f2;
      height: 64px;
      display: flex;
      flex-direction: row;
      align-items: center;
      h3,p{
        margin: 0px;
      }
      h3{
        padding-left: 30px;
      }
      p{
        padding-left: 15px;
      }
    }
    .detail_table{
      padding: 20px;
    }
    .detailTable {
      width:100%;
      border:solid 1px #ccc;
      border-radius:10px;
      border-collapse: collapse;
    }
    .detailTable td {
      border:solid 1px #ccc;
      padding:12px;
    }
    .fleet_body{
      padding: 30px 30px 0px;
      @media screen and (max-width: 560px) {
        padding: 15px;
      }
    }
    .fleetTable {
      width:70%;
      border:solid 1px #ccc;
      border-radius:10px;
      border-collapse: collapse;
      @media screen and (max-width: 560px) {
        width: 100%;
      }
    }
    .fleetTable td {
      border:solid 1px #ccc;
      padding:6px;
    }
    .fleetTable th {
      border:solid 1px #ccc;
      padding:6px;
      font-weight: bold;
      color:#333;
    }
    .profiletile {
      width: 500px;
      overflow: auto;
      background-color: #fff;
      position: fixed;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 3;
      @media screen and (max-width: 560px) {
        width: calc(100% - 30px);
        left: 15px;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        border-radius: 12px;
      }
      p {
        padding-left: 20px;
        margin-bottom: 0px;
      }
      .profiletile_top {
        height: 75px;
        padding-left: 20px;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        h3 {
          margin: 0px;
          height: 75px;
          line-height: 75px;
        }
        span {
          display: inline-block;
          height: 75px;
          width: 75px;
          margin-left: auto;
          background-image: url(../../public/img/boeing/close.svg);
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
        }
      }
      .profileform {
        padding: 20px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1rem;
        line-height: 36px;
        .alarm_row{
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;
          >span{
            width: 140px;
          }
          >div{
            height: 40px;
            flex: 1;
            min-height: 40px;
            textarea,input{
              font-family: 'Inter', sans-serif;
              width: 100%;
              box-sizing: border-box;
              border: 1px solid #BCBCBC;
              resize: none;
              border-radius: 10px;
              font-size: 1rem;
              outline: none;
              padding-left: 10px;
              color: #444;
              height: 40px;
            }
          }
        }
      }
    }
    .context_shadow {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
      bottom: 0px;
      top: 0px;
      left: 0px;
      right: 0px;
    }
  
  </style>